import React from "react";
import { useLocation } from "react-router-dom";
import { useApi } from "../../api";
import { AgentNotes } from "./agent-notes";
import { QuoteInfo } from "../../api/types/search-quote-response";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { BASE_APP_PAth } from "../../api/launchpad-api";

export const MktPlaceQuoteSummary = () => {

    const api = useApi();
    const navigate = useNavigate();
    //retrieve the data passed from the previous page
    const state = useLocation();
    var quoteInfo : QuoteInfo = state.state as QuoteInfo;

    //converting the date to local date
    function convertUTCDateToLocalDate(date:string) {
        var newDate = new Date(date);
        newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
        return newDate.toLocaleString();
    }

    return (
        <>
    <div className="page-header--wrapper">
        <div className="container" style={{maxWidth:"75%" , backgroundColor:"#F5F5F5"}}>
            <div className="row">
                <div className="col-sm-12">               
                    <div className="page-header page-header-bottom-border">
                    <div className="page-header-left">
                        <pre>
                        <h3>Quote# ({quoteInfo.referenceNumber})    </h3>
                        <h4>Premium: {quoteInfo.premium === "" ?  "" : "$"+quoteInfo.premium}    Source: {quoteInfo.source}</h4>                      
                        </pre>                       
                    </div>               
                    <div className="page-header-right">                       
                        {/* <Button onClick={() => { navigate(BASE_APP_PAth + "/search-quote");}} 
                                                className="btn--primary nav-button-next mt-10 xs:w-full sm:w-full"></Button> */}
                                                <span onClick={() => { navigate(BASE_APP_PAth + "/search-quote");}} className="geico-icon icon-close-20 large icon--primary-blue" style={{float:"right"}}></span>
                    </div>
                    </div>                    
                </div>              
            </div>         
            <div style={{float: "right", width: "40%"}}>
                <h2 style={{textAlign: "left", paddingLeft: "3rem"}}> Notes Section</h2>
                <AgentNotes quoteInfoId={quoteInfo.id} />
            </div>           
            <div style={{float: "left", width:"60%"}}>   
                <ul className="list list--two-column" style={{marginRight:"70px",marginLeft:"50px" }}>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}> 
                    <div>Full Name</div>
                    <div>{quoteInfo.firstName}  {quoteInfo.lastName}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Quote Start Date</div>
                    <div>{new Date(quoteInfo.quoteCreatedDate).toLocaleDateString()}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Date of Birth</div>
                    <div>{quoteInfo.dateOfBirth}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Email Address</div>
                    <div>{quoteInfo.email}</div>
                </li>
                 <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Phone Number</div>
                    <div>{quoteInfo.phoneNumber}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Business Information</div>
                    <div>
                        <dl>
                            <dd>{quoteInfo.businessName}</dd>
                            <dd>{quoteInfo.businessAddress}</dd>
                            <dd>{quoteInfo.stateCode}&nbsp;&nbsp;{quoteInfo.zipCode}</dd>
                        </dl>
                    </div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Business Class Description </div>
                    <div>{quoteInfo.businessClassDescription}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Line of Business</div>
                    <div>{quoteInfo.lineOfBusiness.join(", ")}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Product Type</div>
                    <div>{}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Quote Last Modify Date</div>
                    <div>{new Date(quoteInfo.quoteUpdatedDate).toLocaleDateString()}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                    <div>Quote Status</div>
                    <div>{}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                   <div>Quote Declination Reason</div>
                   <div>{}</div>
                </li>
                <li style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                   <div>Most Recent IA/Customer Session login date/time</div>
                   <div>{convertUTCDateToLocalDate(quoteInfo.quoteUpdatedDate).toString()}</div>
                </li>
            </ul>
            </div>
        </div> 
        </div> 
        </>
    
    );

};
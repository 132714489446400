import { useApi } from "../../api";
import React, { useState, useEffect } from "react";
import { Alert, Button } from "../../components";
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { API_ERROR_RESPONSE } from "../../api/types/api-error";
import { API_ERROR_MESSAGE, BASE_APP_PAth } from "../../api/launchpad-api";
import { useNavigate } from "react-router-dom";
import UpsertQuoteInfoInput from "../../components/upsert-partner-quote-input";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { UpsertPartnerQuoteInfoRequest, InitialUpsertQuoteInputs, UpsertInitialInputErrorMessages } from "../../api/types/upsert-partner-quote-info-request";
import { UPSERT_PARNTER_QUOTE_INFO_RESPONSE } from "../../api/types/upsert-partner-quote-info-response";
import { STATE_LOOKUP_RESPONSE, StateLookupRequest } from "../../api/types";

export const AgentUpsertQuoteInfo: React.FC = () => {
    const api = useApi();
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ title: string; message: React.ReactNode }>({ title: "", message: "" });
    const { showLoader, hideLoader } = usePageLoadingContext();
    const [validationErrors, setValidationErrors] = useState(UpsertInitialInputErrorMessages);
    const [upsertQuoteInputs, setUpsertQuoteInputs] = useState<UpsertPartnerQuoteInfoRequest>(InitialUpsertQuoteInputs);
    const [lineofB, setLineofB] = useState<string>();
    const [carrierCodes, setCarrierCodes] = useState<{ value: string, label: string }[]>([]);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        api.commercialAgentApi.getCarrierCodes()
            .then(response => {
                const options = Object.entries(response).map(([key, value]) => ({
                    value: key,
                    label: value
                }));
                setCarrierCodes(options);
            })
            .catch(error => {
                console.error("Error fetching carrier codes:", error);
            });
    }, []);

    const isSearchButtonDisabled = (): boolean => {
        return !isSubmissionValid(upsertQuoteInputs);
    }

    async function stateLookup(zipCode: string, message: string) {
        const request: StateLookupRequest = { zipCode };
        showLoader();

        // Clear the stateCode before making the API call
        setUpsertQuoteInputs(prevState => ({
            ...prevState,
            stateCode: ""
        }));

        try {
            const response = await api.commercialAgentApi.stateLookup(request);

            if (response.type !== STATE_LOOKUP_RESPONSE) {
                message = API_ERROR_MESSAGE;
                setValidationErrors(prevState => ({
                    ...prevState,
                    zipCode: message
                }));
            } else if (!response.data.success) {
                message = response.data.errorMessage || "Please enter a valid zip code.";
                setValidationErrors(prevState => ({
                    ...prevState,
                    zipCode: message
                }));
            } else {
                setUpsertQuoteInputs(prevState => ({
                    ...prevState,
                    stateCode: response.data.state
                }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    }

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }

    function submitForm(this: any): void {
        showLoader();
        if (!isSubmissionValid(upsertQuoteInputs)) {
            setAlertMessage({
                title: "Invalid Submission",
                message: "Please provide valid information according to the submission rules."
            });
            hideLoader();
            return;
        }
        setUpsertQuoteInputs(upsertQuoteInputs);
        upsertQuoteInputs.userType = authContext.getUserType();
        const date = new Date();
        upsertQuoteInputs.quoteCreatedDate = date.toISOString();
        upsertQuoteInputs.quoteUpdatedDate = date.toISOString();
        upsertQuoteInputs.lineOfBusiness = lineofB ? lineofB.split(",") : [];
        api.commercialAgentApi.upsertPartnerQuoteInfo(upsertQuoteInputs)
            .then((response) => {
                if (response.type !== UPSERT_PARNTER_QUOTE_INFO_RESPONSE) {
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Unable to add quote",
                        message: API_ERROR_RESPONSE
                    });
                    hideLoader();
                    return;
                }
                setSuccessMessage("Quote Saved Successfully.");
                resetForm();
                hideLoader();
            });
    }

    function isSubmissionValid(inputs: UpsertPartnerQuoteInfoRequest) {
        const requiredFields = ['zipCode', 'lastName', 'emailId', 'source', 'referenceNumber'];

        for (const field of requiredFields) {
            if (!inputs[field as keyof UpsertPartnerQuoteInfoRequest]) {
                return false;
            }
        }
        return true;
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        console.log(e.target);
        const { name, value } = e.target;
        let errorMessage = "";
        setValidationErrors(UpsertInitialInputErrorMessages);

        if (name === "lineOfBusinesses") {
            setLineofB(e.target.value);
        }
        // Define validation logic for each input
        if (name === "dateOfBirth") {
            const dobRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;
            if (value.length < 10 || !dobRegex.test(value)) {
                errorMessage = "Please enter date as MM/DD/YYYY";
            }
        } else if (name === "emailId") {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (!emailRegex.test(value)) {
                errorMessage = "Please enter email address in this format: Name@service.com";
            }
        } else if (name === "premium") {
            const premiumRegex = /^[0-9]*\.?[0-9]*$/;
            console.log(premiumRegex.test(value));
            if (!premiumRegex.test(value)) {
                errorMessage = "Please enter a valid premium amount.";
            }
        } else if (name === "phoneNumber") {
            const phoneRegex = /^[0-9]{10}$/;
            if (value.length < 10 || !phoneRegex.test(value)) {
                errorMessage = "Oops, it looks like you may need to enter a valid phone number or use the following format xxx-xxx-xxxx.";
            }
        } else if (name === "zipCode") {
            if (value.length < 5) {
                errorMessage = "Please enter a valid zip code.";
            } else {
                stateLookup(value, errorMessage);
            }
            if (errorMessage) {
                upsertQuoteInputs.stateCode = "";
            }
        }
        if (errorMessage) {
            setValidationErrors(prev => ({
                ...prev,
                [name]: errorMessage
            }));
        }
        setUpsertQuoteInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    function resetForm(): void {
        setUpsertQuoteInputs(InitialUpsertQuoteInputs);
        setShowAlert(false);
        setValidationErrors(UpsertInitialInputErrorMessages);
        clearAlertMessage();
    }

    return (
        <div>
            <div className="search-quote-result-container">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        {(showAlert && (alertMessage.title || alertMessage.message) &&
                            <Alert
                                title={alertMessage.title}
                                content={alertMessage.message}
                            // closeAlert={() => clearAlertMessage()}
                            />
                        )}
                        {successMessage && <div className="col-sm-12 col-md-12">{successMessage}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12" >
                        <h2 className="page-header-left" style={{ padding: "1rem", marginBottom: "1rem" }}>
                            Add Partner Quote Info
                        </h2>
                    </div>
                </div>
                <form>
                    <div className="row">
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="5-Digit Zip Code *"
                                name="zipCode"
                                type="tel"
                                value={upsertQuoteInputs.zipCode}
                                onChange={handleInput}
                                error={validationErrors.zipCode} />
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="State Code"
                                name="stateCode"
                                type="text"
                                value={upsertQuoteInputs.stateCode}
                                onChange={handleInput}
                                disabled={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Reference# *"
                                name="referenceNumber"
                                type="text"
                                value={upsertQuoteInputs.referenceNumber}
                                onChange={handleInput}
                                error={validationErrors.referenceNumber} />
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Email *"
                                name="emailId"
                                type="text"
                                value={upsertQuoteInputs.emailId}
                                onChange={handleInput}
                                error={validationErrors.emailId} />
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Phone number"
                                name="phoneNumber"
                                type="tel"
                                placeholder="(___)___-____"
                                value={upsertQuoteInputs.phoneNumber}
                                onChange={handleInput}
                                error={validationErrors.phoneNumber} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="First Name"
                                name="firstName"
                                type="text"
                                value={upsertQuoteInputs.firstName}
                                onChange={handleInput} />
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Last Name *"
                                name="lastName"
                                type="text"
                                value={upsertQuoteInputs.lastName}
                                onChange={handleInput}
                                error={validationErrors.lastName} />
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Date of Birth"
                                name="dateOfBirth"
                                type="tel"
                                value={upsertQuoteInputs.dateOfBirth}
                                onChange={handleInput}
                                error={validationErrors.dateOfBirth} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Line of Business"
                                name="lineOfBusinesses"
                                type="text"
                                onChange={handleInput} />
                        </div>
                        <div className="col-sm-4 ">
                            <div className="col-md-12 form-field">
                                <label htmlFor="source" className="text">Source *</label>
                                <div className="select-box">
                                    <select id="source" name="source" onChange={handleInput}>
                                        <option value="">Select</option>
                                        {carrierCodes.map((carrier, index) => (
                                            <option key={index} value={carrier.value}>
                                                {carrier.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Premium"
                                name="premium"
                                type="text"
                                value={upsertQuoteInputs.premium}
                                onChange={handleInput}
                                error={validationErrors.premium}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Business Name"
                                name="businessName"
                                type="text"
                                value={upsertQuoteInputs.businessName}
                                onChange={handleInput} />
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Business Address"
                                name="businessAddress"
                                type="text"
                                value={upsertQuoteInputs.businessAddress}
                                onChange={handleInput} />
                        </div>
                        <div className="col-sm-4">
                            <UpsertQuoteInfoInput
                                label="Business Class Description"
                                name="businessClassDescription"
                                type="text"
                                value={upsertQuoteInputs.businessClassDescription}
                                onChange={handleInput} />
                        </div>
                    </div>
                </form>
                <div className="row mt-10" >
                    <div className="col-sm-12">
                        <Button disabled={isSearchButtonDisabled()}
                            className="btn--primary nav-button-back mt-10  xs:w-full sm:w-full"
                            children="Save Quote"
                            onClick={(e) => submitForm()}
                        ></Button>
                        <Button
                            className="btn--secondary nav-button-back mt-10  xs:w-full sm:w-full"
                            children="Reset"
                            onClick={(e) => { resetForm() }}
                        ></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
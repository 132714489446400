import { useNavigate } from "react-router-dom";
import { BASE_APP_PAth } from "../api/launchpad-api";
import Pagination from './pagination';

import { QuoteInfo } from "../api/types/search-quote-response";
import { useEffect, useState } from "react";

export const SearchQuoteResults = ({ quoteInfos }: { quoteInfos: QuoteInfo[] }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [quotesPerPage, setQuotesPerPage] = useState(5);
    const [currentQuotes, setCurrentQuotes] = useState<QuoteInfo[]>([]);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setQuotesPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to first page
    };

    const navigate = useNavigate();
    function getMktQuoteSummary(refNum: QuoteInfo): void {
        navigate(BASE_APP_PAth + "/quote-summary", { state: refNum });
        return;
    }

    useEffect(() => {
        const indexOfLastQuote = currentPage * quotesPerPage;
        const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
        setCurrentQuotes(quoteInfos.slice(indexOfFirstQuote, indexOfLastQuote));
    }, [currentPage, quotesPerPage, quoteInfos]);

    return (
        <div>
            <div className="data-table sticky-two-column">
                <table className="table">
                    <thead>
                        <tr>
                            <th role="columnheader">#Quote</th>
                            <th className="sort-header" role="columnheader">Full Name</th>
                            <th className="sort-header" role="columnheader">Business Name</th>
                            <th className="sort-header" role="columnheader">Carrier Name</th>
                            <th className="sort-header" role="columnheader">State</th>
                            <th className="sort-header" role="columnheader">Line of Business</th>
                            <th className="sort-header" role="columnheader">Quoted Premium</th>
                            <th role="columnheader">View Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentQuotes.map((quote, index) => (
                            <tr key={index}>
                                <th className="sticky-column">{quote.referenceNumber}</th>
                                <td>{quote.firstName + " " + quote.lastName}</td>
                                <td>{quote.businessName}</td>
                                <td>{quote.source}</td>
                                <td>{quote.stateCode}</td>
                                <td>{quote.lineOfBusiness.join(", ")}</td>
                                <td>${quote.premium}</td>
                                <th className="col--edit-control">
                                    <div onClick={(e) => getMktQuoteSummary(quote)}><span className="geico-icon geico-icon--small geico-icon--actionable icon-edit" ></span></div>
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="table-footer">
                <div className="form-field">
                    <label className="text">Rows per page</label>
                    <div className="select">
                        <select onChange={handleRowsPerPageChange} value={quotesPerPage}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(quoteInfos.length / quotesPerPage)}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
};
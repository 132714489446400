export type UpsertPartnerQuoteInfoRequest = {  
    lineOfBusiness: string[];
    stateCode: string;
    emailId: string;
    firstName: string;
    lastName: string;
    zipCode: string;
    dateOfBirth: string;
    phoneNumber: string;
    referenceNumber: string;
    source: string;
    userType: string;
    premium: string;
    firstApplicationFormID: string;
    token: string;
    businessName: string;
    businessAddress: string;
    businessClassDescription: string;
    quoteUpdatedDate: string;
    quoteCreatedDate: string;
  }
  
  // // todo: change to save 
  export const InitialUpsertQuoteInputs = (): UpsertPartnerQuoteInfoRequest => {
    return {
        lineOfBusiness: [""],
        stateCode: "",
        emailId: "",
        firstName: "",
        lastName: "",
        zipCode: "",
        dateOfBirth: "",
        phoneNumber: "",
        referenceNumber: "",
        source: "",
        userType: "",
        premium: "",
        firstApplicationFormID: "",
        token: "",
        businessName: "",
        businessAddress: "",
        businessClassDescription: "",
        quoteUpdatedDate: "",
        quoteCreatedDate: ""
    };
  }
  
export const UpsertInitialInputErrorMessages = (): UpsertPartnerQuoteInfoRequest => {
    return {
        lineOfBusiness: [""],
        stateCode: "",
        emailId: "",
        firstName: "",
        lastName: "",
        zipCode: "",
        dateOfBirth: "",
        phoneNumber: "",
        referenceNumber: "",
        source: "",
        userType: "",
        premium: "",
        firstApplicationFormID: "",
        token: "",
        businessName: "",
        businessAddress: "",
        businessClassDescription: "",
        quoteUpdatedDate: "",
        quoteCreatedDate: ""
    };
  }